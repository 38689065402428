<template>
  <li class="nav-item" :class="{ 'menu-open': isOpenLocal }">
    <a class="nav-link" @click.stop="toggleMenu">
      <i v-if="icon" :class="`nav-icon ${icon}`"></i>
      <p>
        <slot />
        <i v-if="hasSubMenu" class="right fas fa-angle-left"></i>
      </p>
    </a>
    <ul v-if="hasSubMenu" class="nav nav-treeview" :style="{ display: isOpenLocal ? 'block' : 'none' }">
      <li v-for="item in subMenu" :key="item.value" class="nav-item submenu-item">
        <a v-if="item.action" 
           class="nav-link" 
           @click="handleAction(item)"
           style="cursor: pointer;">
          <i v-if="item.icon" class="nav-icon" :class="item.icon"></i>
          <p>
            {{ item.label }}
            <span v-if="item.badge" class="right badge" :class="item.badge.class">
              {{ item.badge.text }}
            </span>
          </p>
        </a>
        <NavItemComponent
          v-else-if="item.subMenu"
          :icon="item.icon"
          :link="item.link"
          :subMenu="item.subMenu"
        >
          {{ item.label }}
        </NavItemComponent>
        <router-link
          v-else
          class="nav-link"
          :to="item.link"
          @click="handleClick(item.value)"
        >
          <i v-if="item.icon" class="nav-icon" :class="item.icon"></i>
          <p>{{ item.label }}</p>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    subMenu: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpenLocal: false,
    };
  },

  computed: {
    hasSubMenu() {
      return this.subMenu.length > 0;
    },
    menuStateKey() {
      return `${this.link}-open`;
    }
  },

  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$store.state.menuOpenState, this.menuStateKey)) {
      this.isOpenLocal = this.$store.state.menuOpenState[this.menuStateKey];
    } else {
      this.$store.commit('setMenuOpenState', { isOpen: false, menuKey: this.menuStateKey });
    }
  },

  methods: {
    toggleMenu() {
      if (this.hasSubMenu) {
        this.isOpenLocal = !this.isOpenLocal;
        this.$store.commit('setMenuOpenState', { isOpen: this.isOpenLocal, menuKey: this.menuStateKey });
      } else {
        this.$router.push(this.link);
      }
    },
    handleClick(value) {
      this.$store.commit('setPriceCategory', value);
    },
    handleAction(item) {
      if (item.action && typeof item.action === 'function') {
        item.action();
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}
.submenu-item {
  margin-left: 20px;
}


.nav-pills .nav-link:not(.active):hover {
          background-color: rgba(255, 255, 255, .1);
    color: #fff;
}
</style>
